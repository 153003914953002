import React, { useState } from "react";
import PropTypes from "prop-types";
import { Grid, Paper, Typography, Button } from "@mui/material";
import Header_Layout from "../../components/layouts/Header_Layout";
import { HeadFC, navigate } from "gatsby";
import Desktop from "../../components/desktop/Desktop";

type Props = {};

const page_styles = {
  color: "#232129",
  fontFamily: "Ubuntu, Roboto, sans-serif, serif",
  fontSize: 24,
  paddingTop: 48,
  backgroundColor: "#39B54A",
};

const Landing = (props: Props) => {
  return (
    <Header_Layout fullWidth={true}>
      <main style={page_styles}>
        <Grid container spacing={4} alignItems="flex-end">
          <Grid item xs={12}>
            <Typography
              sx={{ color: "#fff" }}
              component="h1"
              variant="h3"
              align="center"
              color="textPrimary"
              gutterBottom
            >
              Thinking about upgrading your desktop?
            </Typography>
            <Typography sx={{ color: "#fff" }} align="center">
              We'll help you sell, upgrade, and maintain your build and your
              components.
            </Typography>
            <Grid
              container
              mt={4}
              direction="column"
              alignItems="center"
              justifyContent="center"
            >
              <Grid item xs={12}>
                <Grid container direction="row">
                  <Grid item md={2} sm={0}></Grid>
                  <Grid
                    item
                    md={4}
                    sm={6}
                    xs={12}
                    mb={4}
                    sx={{ minWidth: 600 }}
                  >
                    <Desktop
                      is_static={false}
                      case_enabled={true}
                      cpu_enabled={true}
                      cpu_cooler_enabled={true}
                      gpu_enabled={true}
                      motherboard_enabled={true}
                      operating_system_enabled={true}
                      psu_enabled={true}
                      ram_enabled={true}
                      storage_enabled={true}
                      case_fan_enabled={true}
                    />
                  </Grid>
                  <Grid
                    item
                    md={4}
                    sm={6}
                    xs={12}
                    sx={{ textAlign: "center", p: 4 }}
                  >
                    <Typography
                      variant="h2"
                      mb={4}
                      sx={{ color: "#fff" }}
                      align="center"
                    >
                      Dive deep into your build.
                    </Typography>
                    <Typography sx={{ color: "#fff" }} align="center" mb={4}>
                      Tell us about your build or component to gain access to
                      upgrade recommendations and to discover your build's
                      worth.
                    </Typography>
                    <Button
                      size={"medium"}
                      variant="contained"
                      sx={{ background: "black" }}
                      onClick={() => navigate("/create")}
                    >
                      {`Try it now`}
                    </Button>
                  </Grid>
                  <Grid item md={2} sm={0}></Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </main>
    </Header_Layout>
  );
};

Landing.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default Landing;

export const Head: HeadFC = () => <title>BitLot.app</title>;
